<template>
  <v-row>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("salesSummaryPage.loading") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogViewIndivisualBill" :width="isMobile ? '95%' : '800px'">
    <v-card>
      <v-card-text class="overflow-auto">
        <div>
          <h4 class="user-name">Order Number: {{ orderNo }}</h4>
          <p class="order-info">
            Dining Method: {{ diningMethod }}<br />
            Order at: {{ orderAt }}<br />
            Close By: {{ closeBy }}
          </p>
          <table id="customers">
            <thead>
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in individualBill" :key="item.order_detail_id">
                <td>{{ item.product_name }}
                    <br><span v-if="item.product_variant_name">({{item.product_variant_name}})<br></span>
                    <span v-html="item.modifier_name"></span>
                </td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.price }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right">Subtotal:</td>
                <td>{{ subtotal }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right">Tax:</td>
                <td>{{ taxAmount }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right">Charge:</td>
                <td>{{ chargeAmount }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right">Discount:</td>
                <td>-{{ discountAmount }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right">Rounding:</td>
                <td>{{ rounding }}</td>
              </tr>
              <tr>
                <td colspan="1"></td>
                <td class="align-right total-label">Total:</td>
                <td class="total-value">{{ total }}</td>
              </tr>
            </tbody>
          </table>
          <div class="payment-method" v-html="paymentMethodText"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="downloadAndClose">
          Download As PDF
        </v-btn>
        <v-btn color="danger darken-1" text @click="dialogViewIndivisualBill = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    <v-col cols="12" md="3">
      <test-card
        chip-color="info lighten-5"
        :title="$t('salesSummaryPage.grossSales')"
        :amount="grossSales"
        :footer-text="$t('salesSummaryPage.inToday')"
        footer-class="info--text"
        card-icon="mdi-chart-bar"
        color-icon="info--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="primary lighten-5"
        :title="$t('salesSummaryPage.totalSales')"
        :amount="dailySales"
        :footer-text="$t('salesSummaryPage.inToday')"
        footer-class="primary--text"
        card-icon="mdi-chart-line-variant"
        color-icon="primary--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="success lighten-5"
        :title="$t('salesSummaryPage.totalSales')"
        :amount="weeklySales"
        :footer-text="$t('salesSummaryPage.inThisWeek')"
        footer-class="success--text"
        card-icon="mdi-format-list-bulleted"
        color-icon="success--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="info lighten-5"
        :title="$t('salesSummaryPage.totalSales')"
        :amount="monthlySales"
        :footer-text="$t('salesSummaryPage.inThisMonth')"
        footer-class="info--text"
        card-icon="mdi-account-multiple"
        color-icon="info--text"
      />
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="12"   sm="8"
            ><v-card-title>{{
              $t("salesSummaryPage.salesChart")
            }}</v-card-title></v-col
          >
          <v-col cols="10"  sm="3"
            ><v-select
              class="mt-3 ml-2"
              v-model="range"
              :items="options"
              solo
              background-color="grey lighten-4"
              dense
            ></v-select
          ></v-col>
          <v-col cols="2"  sm="1"> 
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="showIcon"
                  @click="date = []"
                  v-on="on"
                  class="mt-3"
                  icon
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="date" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  {{ $t("salesSummaryPage.choose") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card-text>{{ betweenDate }}</v-card-text>
        <v-row>
          <v-col>
            <div v-show="showChart">
              <apexchart :options="chartOptions" :series="series" />
            </div>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <div class="d-flex align-center justify-space-between pr-3">
          <v-card-title>{{ $t("salesSummaryPage.salesTable") }}</v-card-title>
          <v-spacer></v-spacer>
          <div v-if="filterList.length != 0">
            <export-excel
              :data="settlementListToPrint"
              :fields="json_fields"
              :name="filename"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                </template>
                <span>{{
                  $t("salesSummaryPage.downloadOverallSalesReport")
                }}</span>
              </v-tooltip>
            </export-excel>
          </div>
        </div>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="filterList"
            :page.sync="page"
            :no-data-text="$t('dailyModifierSalesPage.noData')"
            :items-per-page="itemsPerPage"
            single-expand
            :expanded.sync="expanded"
            show-expand
            @page-count="pageCount = $event"
            item-key="settlement_id"
            hide-default-footer
            @item-expanded="handleClick"
          >
            <template v-slot:item.total_charge="{ item }">
              <span v-if="item.total_charge != ''">{{
                item.total_charge
              }}</span>
              <span v-else>0.00</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="ma-5">
                  <div>
                    <v-col>
                      <export-excel
                        :data="billList"
                        :fields="bill_fields"
                        :name="'Settlement_id_' + item.settlement_id + '.xls'"
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-download
                            </v-icon>
                          </template>
                          <span>{{
                            $t("salesSummaryPage.downloadBillSalesReport")
                          }}</span>
                        </v-tooltip>
                      </export-excel>
                    </v-col>
                  </div>
                  <v-data-table
                    :headers="billHeaders"
                    :items="billList"
                    :no-data-text="$t('dailyModifierSalesPage.noData')"
                    item-key="order_id"
                    :loading="billListLoading"
                    loading-text="Loading... Please wait"
                    @click:row="rowClick"
                  >
                    <template v-slot:item.tax_amount="{ item }">
                      <span v-if="item.tax_amount != null">{{
                        item.tax_amount
                      }}</span>
                      <span v-else>0.00</span>
                    </template>

                    <template v-slot:item.charge_amount="{ item }">
                      <span v-if="item.charge_amount != null">{{
                        item.charge_amount
                      }}</span>
                      <span v-else>0.00</span>
                    </template>

                    <template v-slot:item.promotion_amount="{ item }">
                      <span v-if="item.promotion_amount == null">0.00</span>
                      <span v-else>{{ item.promotion_amount }}</span>
                    </template>

                    <template v-slot:item.refund_key="{ item }">
                      <span v-if="item.refund_key != ''">{{
                        item.final_amount
                      }}</span>
                      <span v-else-if="item.refund_key == ''">0.00</span>
                    </template>

                    <template v-slot:item.refund_by="{ item }">
                      <span v-if="item.refund_by == null">-</span>
                      <span v-else>{{ item.refund_by }}</span>
                    </template>
                  </v-data-table>
                </div>
              </td>
            </template>
          </v-data-table>
          <div>
            <VueHtml2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              :filename="'order_number_' + orderNo"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              ref="html2Pdf"
            >
              <section slot="pdf-content" style="padding: 20px">
                <h4 class="user-name">Order Number: {{ orderNo }}</h4>
                <p
                  style="
                    font-size: large;
                    margin-bottom: 30px;
                    margin-top: 10px;
                  "
                >
                  Dining Method: {{ diningMethod }}
                  <br />
                  Order at: {{ orderAt }}
                  <br />
                  Close By: {{ closeBy }}
                </p>
                <table id="customers">
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                  <tr
                    v-for="item in individualBill"
                    :key="item.order_detail_id"
                  >
                    <td>{{ item.product_name }}
                      <br><span v-if="item.product_variant_name">({{item.product_variant_name}})<br></span>
                      <span v-html="item.modifier_name"></span>

                    </td>
  
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>
                      {{ item.price }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>
                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Subtotal:
                    </td>
                    <td style="border-left: none !important">{{ subtotal }}</td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>
                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Tax:
                    </td>
                    <td style="border-left: none !important">
                      {{ taxAmount }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>
                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Charge:
                    </td>
                    <td style="border-left: none !important">
                      {{ chargeAmount }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>
                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Discount:
                    </td>
                    <td style="border-left: none !important">
                      -{{ discountAmount }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>

                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Rounding:
                    </td>
                    <td style="border-left: none !important">{{ rounding }}</td>
                  </tr>
                  <tr>
                    <td colspan="1"></td>

                    <td
                      style="
                        border-right: none !important;
                        text-align: right;
                        font-weight: bold;
                      "
                    >
                      Total:
                    </td>
                    <td style="border-left: none !important; font-weight: bold">
                      {{ total }}
                    </td>
                  </tr>
                </table>
                <div
                  style="
                    font-size: large;
                    text-align: right;
                  "
                  id="output"
                ></div>
              </section>
            </VueHtml2pdf>
          </div>
          <div class="text-center py-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";
import { pageUp } from "@syncfusion/ej2-vue-grids";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      domain: BASEURL,
      company_id: "",
      dailySales: "0.00",
      showIcon: false,
      showChart: false,
      date: [],
      modal: false,
      dialogLoading: false,
      weeklySales: "",
      monthlySales: "",
      grossSales: "",
      settlementList: "",
      orderNo: "",
      diningMethod: "",
      paymentMethod: "",
      paymentMethodSales: "",
      dialogViewIndivisualBill: false,
      taxAmount: "",
      chargeAmount: "",
      discountAmount: "",
      isMobile: window.innerWidth <= 600,
      billListLoading: false,
      closeBy: "",
      orderAt: "",
      subtotal: "",
      rounding: "",
      total: "",
      paymentMethodText: "",
      betweenDate: "",
      selectedLocale: this.$i18n.locale,
      settlementListToPrint: "",
      branchList: [],
      filterList: [],
      expanded: [],
      billList: [],
      individualBill: [],
      range: "Today",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paymentList: [],
      options: [
        "Today",
        "Last 3 Days",
        "This Week",
        "Last 2 weeks",
        "This Month",
        "Last 3 Months",
        "This Year",
        "Custom Range",
      ],

      //chart
      series: [
        {
          name: "Sales",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          width: 600,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "Date/Month/Year",
        },
      },
    };
  },
  computed: {
    filename() {
      if (this.range == "Custom Range") {
        return this.betweenDate + " Sales Report.xls";
      } else {
        return this.range + " Sales Report.xls";
      }
    },
    getTotal() {
      var total = 0;
      for (let i = 0; i < this.individualBill.length; i++) {
        total += parseFloat(this.individualBill[i].price);
      }
      return total.toFixed(2).toString();
    },
    billHeaders() {
      const logHeader = [
        {
          text: this.$t("salesSummaryPage.orderNumber"),
          align: "start",
          sortable: false,
          value: "order_number",
        },

        {
          text: this.$t("salesSummaryPage.diningMethod"),
          align: "start",
          sortable: false,
          value: "dining_name",
        },

        {
          text: "Gross Sales",
          align: "start",
          sortable: false,
          value: "gross_sales",
        },

        {
          text: this.$t("salesSummaryPage.discountAmount"),
          align: "start",
          value: "discount_amount",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.chargeAmount"),
          align: "start",
          value: "charge_amount",
          sortable: false,
        },

        {
          text: "Net Sales",
          align: "start",
          sortable: false,
          value: "net_sales",
        },

        {
          text: this.$t("salesSummaryPage.taxAmount"),
          align: "start",
          value: "tax_amount",
          sortable: false,
        },

        
        {
          text: "Amount before Rounding",
          align: "start",
          sortable: false,
          value: "amount",
        },

        {
          text: "Rounding",
          align: "start",
          sortable: false,
          value: "rounding",
        },

        {
          text: this.$t("salesSummaryPage.totalSales"),
          align: "start",
          value: "final_amount",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.closeBy"),
          align: "start",
          sortable: false,
          value: "close_by",
        },

        {
          text: "Refund Amount",
          align: "start",
          sortable: false,
          value: "refund_key",
        },

        {
          text: this.$t("salesSummaryPage.refundBy"),
          align: "start",
          sortable: false,
          value: "refund_by",
        },

        {
          text: this.$t("salesSummaryPage.orderAt"),
          align: "start",
          sortable: false,
          value: "created_at",
        },
      ];

      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].name == "TouchNGo eWallet Scan (ipay88)") {
          const paymentHeader = {
            text: "TNG(ipay88)",
            align: "start",
            value: "tngipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Boost Wallet Scan (ipay88)") {
          const paymentHeader = {
            text: "Boost(ipay88)",
            align: "start",
            value: "boostipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "E-Wallet") {
          const paymentHeader = {
            text: "eWallet",
            align: "start",
            value: "ewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "MaybankQR Scan (ipay88)") {
          const paymentHeader = {
            text: "MaybankQR(ipay88)",
            align: "start",
            value: "maybankipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Unionpay Scan (ipay88)") {
          const paymentHeader = {
            text: "UnionPay(ipay88)",
            align: "start",
            value: "unionpayipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "GrabPay Scan (ipay88)") {
          const paymentHeader = {
            text: "GrabPay(ipay88)",
            align: "start",
            value: "grabpayipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Duitnow eWallet") {
          const paymentHeader = {
            text: "DuitnowEwallet",
            align: "start",
            value: "duitnowewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "TouchNGo eWallet") {
          const paymentHeader = {
            text: "TNGEwallet",
            align: "start",
            value: "tngewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Boost eWallet") {
          const paymentHeader = {
            text: "BoostEwallet",
            align: "start",
            value: "boostewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "GrabPay eWallet") {
          const paymentHeader = {
            text: "GrabPayEwallet",
            align: "start",
            value: "grabpayewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Bai Jia 百家") {
          const paymentHeader = {
            text: "Baijia",
            align: "start",
            value: "baijia_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Shopee Food") {
          const paymentHeader = {
            text: "ShopeeFood",
            align: "start",
            value: "shopeefood_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else {
          const paymentHeader = {
            text: this.paymentList[i].name,
            align: "start",
            value: this.paymentList[i].name + "_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        }
      }

      return logHeader;
    },

    bill_fields() {
        let logJsonField = {
        "Order number(#)": "order_number",
        "Dining Method": "dining_name",

        "Gross Sales": "gross_sales",

        "Discount Amount": {
          field: "discount_amount",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },
        "Charge Amount": {
          field: "charge_amount",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },

        "Net Sales": "net_sales",

        "Tax Amount": {
          field: "tax_amount",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },

        "Amount before rouding": "amount",
        Rounding: "rounding",
        "Total Sales": "final_amount",
        "Close by": "close_by",
        "Refund Amount": "refund_amount",
        "Refund By": {
          field: "refund_by",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "-";
            }
          },
        },
        "Order at": "created_at",
      };

      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].name == "TouchNGo eWallet Scan (ipay88)") {
          logJsonField["TNG(ipay88)"] = "tngipay88_sales";
        } else if (this.paymentList[i].name == "Boost Wallet Scan (ipay88)") {
          logJsonField["Boost(ipay88)"] = "boostipay88_sales";
        } else if (this.paymentList[i].name == "E-Wallet") {
          logJsonField["E-Wallet"] = "ewallet_sales";
        } else if (this.paymentList[i].name == "MaybankQR Scan (ipay88)") {
          logJsonField["MaybankQR(ipay88)"] = "maybankipay88_sales";
        } else if (this.paymentList[i].name == "Unionpay Scan (ipay88)") {
          logJsonField["UnionPay(ipay88)"] = "unionpayipay88_sales";
        } else if (this.paymentList[i].name == "GrabPay Scan (ipay88)") {
          logJsonField["GrabPay(ipay88)"] = "grabpayipay88_sales";
        } else if (this.paymentList[i].name == "Duitnow eWallet") {
          logJsonField["DuitnowEwallet"] = "duitnowewallet_sales";
        } else if (this.paymentList[i].name == "TouchNGo eWallet") {
          logJsonField["TNGEwallet"] = "tngewallet_sales";
        } else if (this.paymentList[i].name == "Boost eWallet") {
          logJsonField["BoostEwallet"] = "boostewallet_sales";
        } else if (this.paymentList[i].name == "GrabPay eWallet") {
          logJsonField["GrabPayEwallet"] = "grabpayewallet_sales";
        } else if (this.paymentList[i].name == "Bai Jia 百家") {
          logJsonField["Baijia"] = "baijia_sales";
        } else if (this.paymentList[i].name == "Shopee Food") {
          logJsonField["ShopeeFood"] = "shopeefood_sales";
        } else {
          let name = this.paymentList[i].name;
          let value = this.paymentList[i].name + "_sales";
          logJsonField[name] = value;
        }
      }

      return logJsonField;
    },

    json_fields() {
      let logJsonField = {
        "Settlement ID": "settlement_id",
        Branch: "name",
        "Total Bill": "total_bill",
        "Total Refund Bill": "total_refund_bill",
        "Total Refund Amount": "total_refund_amount",
        "Gross Sales": "gross_sales",
        "Discount amount": "total_discount",
        "Total Charge": {
          field: "total_charge",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },
        "Net Sales": "net_sales",
        "Total Tax": "total_tax",
        "Total Sales": "total_sales",
        "Opening At": "opened_at",
        "Closing At": "created_at",
      };

      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].name == "TouchNGo eWallet Scan (ipay88)") {
          logJsonField["TNG(ipay88)"] = "tngipay88_sales";
        } else if (this.paymentList[i].name == "Boost Wallet Scan (ipay88)") {
          logJsonField["Boost(ipay88)"] = "boostipay88_sales";
        } else if (this.paymentList[i].name == "E-Wallet") {
          logJsonField["E-Wallet"] = "ewallet_sales";
        } else if (this.paymentList[i].name == "MaybankQR Scan (ipay88)") {
          logJsonField["MaybankQR(ipay88)"] = "maybankipay88_sales";
        } else if (this.paymentList[i].name == "Unionpay Scan (ipay88)") {
          logJsonField["UnionPay(ipay88)"] = "unionpayipay88_sales";
        } else if (this.paymentList[i].name == "GrabPay Scan (ipay88)") {
          logJsonField["GrabPay(ipay88)"] = "grabpayipay88_sales";
        } else if (this.paymentList[i].name == "Duitnow eWallet") {
          logJsonField["DuitnowEwallet"] = "duitnowewallet_sales";
        } else if (this.paymentList[i].name == "TouchNGo eWallet") {
          logJsonField["TNGEwallet"] = "tngewallet_sales";
        } else if (this.paymentList[i].name == "Boost eWallet") {
          logJsonField["BoostEwallet"] = "boostewallet_sales";
        } else if (this.paymentList[i].name == "GrabPay eWallet") {
          logJsonField["GrabPayEwallet"] = "grabpayewallet_sales";
        } else if (this.paymentList[i].name == "Bai Jia 百家") {
          logJsonField["Baijia"] = "baijia_sales";
        } else if (this.paymentList[i].name == "Shopee Food") {
          logJsonField["ShopeeFood"] = "shopeefood_sales";
        } else {
          let name = this.paymentList[i].name;
          let value = this.paymentList[i].name + "_sales";
          logJsonField[name] = value;
        }
      }

      return logJsonField;
    },
    headers() {
      const logHeader = [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "ID(#)",
          align: "start",
          sortable: false,
          value: "settlement_id",
        },

        {
          text: this.$t("salesSummaryPage.branch"),
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: this.$t("salesSummaryPage.totalBill"),
          align: "start",
          sortable: false,
          value: "total_bill",
        },

        {
          text: this.$t("salesSummaryPage.totalRefundBill"),
          align: "start",
          value: "total_refund_bill",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.totalRefundAmount"),
          align: "start",
          value: "total_refund_amount",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.grossSales"),
          align: "start",
          value: "gross_sales",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.discountAmount"),
          align: "start",
          value: "total_discount",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.totalCharge"),
          align: "start",
          sortable: false,
          value: "total_charge",
        },

        {
          text: this.$t("dailySalesPage.netSales"),
          align: "start",
          sortable: false,
          value: "net_sales",
        },

        {
          text: this.$t("salesSummaryPage.totalTax"),
          align: "start",
          sortable: false,
          value: "total_tax",
        },

        {
          text: this.$t("salesSummaryPage.totalSales"),
          align: "start",
          value: "total_sales",
          sortable: false,
        },

        {
          text: this.$t("salesSummaryPage.openingAt"),
          align: "start",
          value: "opened_at",
          sortable: true,
        },

        {
          text: this.$t("salesSummaryPage.closingAt"),
          align: "start",
          value: "created_at",
          sortable: true,
        },
      ];

      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].name == "TouchNGo eWallet Scan (ipay88)") {
          const paymentHeader = {
            text: "TNG(ipay88)",
            align: "start",
            value: "tngipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Boost Wallet Scan (ipay88)") {
          const paymentHeader = {
            text: "Boost(ipay88)",
            align: "start",
            value: "boostipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "E-Wallet") {
          const paymentHeader = {
            text: "eWallet",
            align: "start",
            value: "ewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "MaybankQR Scan (ipay88)") {
          const paymentHeader = {
            text: "MaybankQR(ipay88)",
            align: "start",
            value: "maybankipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Unionpay Scan (ipay88)") {
          const paymentHeader = {
            text: "UnionPay(ipay88)",
            align: "start",
            value: "unionpayipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "GrabPay Scan (ipay88)") {
          const paymentHeader = {
            text: "GrabPay(ipay88)",
            align: "start",
            value: "grabpayipay88_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Duitnow eWallet") {
          const paymentHeader = {
            text: "DuitnowEwallet",
            align: "start",
            value: "duitnowewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "TouchNGo eWallet") {
          const paymentHeader = {
            text: "TNGEwallet",
            align: "start",
            value: "tngewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Boost eWallet") {
          const paymentHeader = {
            text: "BoostEwallet",
            align: "start",
            value: "boostewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "GrabPay eWallet") {
          const paymentHeader = {
            text: "GrabPayEwallet",
            align: "start",
            value: "grabpayewallet_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Bai Jia 百家") {
          const paymentHeader = {
            text: "Baijia",
            align: "start",
            value: "baijia_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else if (this.paymentList[i].name == "Shopee Food") {
          const paymentHeader = {
            text: "ShopeeFood",
            align: "start",
            value: "shopeefood_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        } else {
          const paymentHeader = {
            text: this.paymentList[i].name,
            align: "start",
            value: this.paymentList[i].name + "_sales",
            sortable: true,
          };
          logHeader.push(paymentHeader);
        }
      }

      return logHeader;
    },
  },

  mounted() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  watch: {
    range() {
      this.betweenDate = "";
      this.filterList = [];
      this.showIcon = false;
      var moment = require("moment");
      var startWeek = moment().startOf("week");
      var endWeek = moment().endOf("week");
      var day = moment().format("DD-MM-YYYY");
      var year = moment().format("YYYY");
      var month = moment().format("MM");
      this.series = [
        {
          name: "Sales",
          data: [],
        },
      ];

      if (this.range == "Today") {
        var obj = {
          x: moment().format("DD MMM"),
          y: this.dailySales,
        };
        this.series[0].data.push(obj);
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("DD-MM-YYYY") ==
            day
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
      } else if (this.range == "Last 3 Days") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment().subtract(3, "days"),
              moment(),
              undefined,
              "[)"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
        // count date
        var date = [];
        var num = 3;
        while (num >= 1) {
          var tempDate = moment().subtract(num, "days");
          date.push(moment(tempDate));
          num--;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ==
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Week") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment(startWeek).add(1, "days"),
              moment(endWeek).add(1, "days"),
              undefined,
              "[]"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 1;
        while (num <= 7) {
          var tempDate = moment(startWeek).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ==
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Last 2 weeks") {
        var start2Week = moment(startWeek).subtract(14, "days");
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment(start2Week).add(1, "days"),
              moment(start2Week).add(15, "days"),
              undefined,
              "[]"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 1;
        while (num <= 14) {
          var tempDate = moment(start2Week).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ===
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Month") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (moment(this.settlementList[j].created_at).format("MM") == month) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var tempDate = moment().startOf("month");
        var startMonth = moment(tempDate).subtract(1, "days");
        var date = [];
        var num = 1;
        while (num < moment(startMonth).daysInMonth()) {
          var tempDate = moment(startMonth).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ===
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Last 3 Months") {
        var startThisMonth = moment().startOf("month");
        var lastThreeMonth = moment(startThisMonth).subtract(3, "months");
        var tempMonth = moment(lastThreeMonth).add(2, "months");

        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              lastThreeMonth,
              moment(tempMonth).endOf("month")
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 3;
        while (num >= 1) {
          var tempDate = moment(startThisMonth).subtract(num, "months");
          date.push(moment(tempDate));
          num--;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Year") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("YYYY") == year
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var yearMonth = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ];
        var date = [];
        for (let m = 0; m < yearMonth.length; m++) {
          var temp = moment(yearMonth[m] + "-" + year, "MM-YYYY");
          date.push(temp);
        }
        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Custom Range") {
        this.showIcon = true;
      }

      this.settlementListToPrint = [...this.filterList];

      // Sum of total_charge
      const totalChargeSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_charge) || 0,
        0
      );

      // Sum of total_bill
      const totalBillSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_bill) || 0,
        0
      );

      // Sum of total_tax
      const totalTaxSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_tax) || 0,
        0
      );

      // Sum of Gross sales
      const grossSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.gross_sales) || 0,
        0
      );

      // Sum of net sales
      const netSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.net_sales) || 0,
        0
      );

      // Sum of total_cancellation
      const totalCancellationSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_cancellation) || 0,
        0
      );

      // Sum of total_discount
      const totalDiscountSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_discount) || 0,
        0
      );

      // Sum of total_refund_amount
      const totalRefundAmountSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_refund_amount) || 0,
        0
      );

      // Sum of total_refund_bill
      const totalRefundBillSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_refund_bill) || 0,
        0
      );

      // Sum of total_sales
      const totalSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_sales) || 0,
        0
      );
      // payment_method
      // Sum of total_sales
      const totalCashSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Cash_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalCardSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Card_sales) || 0,
        0
      );
      // Sum of total_sales
      const totalEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.ewallet_sales) || 0,
        0
      );
      // Sum of total_sales
      const totalCheckSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Check_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalOtherSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Other_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalTNGipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.tngipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBoostipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.boostipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalMaybankipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.maybankipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalUnionPayipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.unionpayipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabPayipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.grabpayipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalDuitnowEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.duitnowewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalTNGEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.tngewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBoostEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.boostewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabPayEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.grabpayewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalFoodpandaSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Foodpanda_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabFoodSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.GrabFood_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalWalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Wallets_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalAlipaySales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Alipay_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBaijiaSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.baijia_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalShopeeFoodSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.shopeefood_sales) || 0,
        0
      );

      // Clone the original data structure but empty other fields
      const summedData = {
        soft_delete: "",
        opened_at: "",
        updated_at: "",
        created_at: "",
        sync_status: 1,
        status: 0,
        settlement_by: "",
        settlement_by_user_id: "",
        total_charge: totalChargeSum.toFixed(2),
        total_tax: totalTaxSum.toFixed(2),
        total_cancellation: totalCancellationSum,
        total_discount: totalDiscountSum.toFixed(2),
        //payment
        Cash_sales: totalCashSales.toFixed(2),
        Card_sales: totalCardSales.toFixed(2),
        ewallet_sales: totalEwalletSales.toFixed(2),
        Check_sales: totalCheckSales.toFixed(2),
        Other_sales: totalOtherSales.toFixed(2),
        tngipay88_sales: totalTNGipay88Sales.toFixed(2),
        boostipay88_sales: totalBoostipay88Sales.toFixed(2),
        maybankipay88_sales: totalMaybankipay88Sales.toFixed(2),
        unionpayipay88_sales: totalUnionPayipay88Sales.toFixed(2),
        grabpayipay88_sales: totalGrabPayipay88Sales.toFixed(2),
        duitnowewallet_sales: totalDuitnowEwalletSales.toFixed(2),
        tngewallet_sales: totalTNGEwalletSales.toFixed(2),
        boostewallet_sales: totalBoostEwalletSales.toFixed(2),
        grabpayewallet_sales: totalGrabPayEwalletSales.toFixed(2),
        Foodpanda_sales: totalFoodpandaSales.toFixed(2),
        GrabFood_sales: totalGrabFoodSales.toFixed(2),
        Wallets_sales: totalWalletSales.toFixed(2),
        Alipay_sales: totalAlipaySales.toFixed(2),
        baijia_sales: totalBaijiaSales.toFixed(2),
        shopeefood_sales: totalShopeeFoodSales.toFixed(2),
        //end
        total_refund_amount: totalRefundAmountSum.toFixed(2),
        total_refund_bill: totalRefundBillSum,
        gross_sales: grossSalesSum.toFixed(2),
        net_sales: netSalesSum.toFixed(2),
        total_sales: totalSalesSum.toFixed(2),
        total_bill: totalBillSum,
        name: "",
        settlement_key: "",
        settlement_id: "Total",
      };

      this.settlementListToPrint.push(summedData);
    },
    date() {
      var moment = require("moment");
      var temValue = "";
      this.filterList = [];
      this.betweenDate = "";
      this.series = [
        {
          name: "Sales",
          data: [],
        },
      ];
      if (this.date.length > 1) {
        if (moment(this.date[0]).isBefore(moment(this.date[1]))) {
          this.betweenDate = "Date: " + this.date[0] + " - " + this.date[1];
          for (let j = 0; j < this.settlementList.length; j++) {
            temValue = moment(this.settlementList[j].created_at).format(
              "YYYY-MM-DD"
            );
            if (
              moment(temValue).isBetween(
                moment(this.date[0]),
                moment(this.date[1]),
                undefined,
                "[]"
              )
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }

          var x = new moment(this.date[0]);
          var y = new moment(this.date[1]);
          var duration = y.diff(x, "days");
          var monthDuration = y.diff(x, "months");
          var yearDuration = y.diff(x, "years");

          if (duration <= 31) {
            // count date
            var tempDay = [];
            var init = 1;
            var num = duration;
            tempDay.push(moment(this.date[0]));
            while (init <= num) {
              var tempDate = moment(this.date[0]).add(init, "days");
              tempDay.push(moment(tempDate));
              init++;
            }
            // assign data
            for (let o = 0; o < tempDay.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempDay[o]).format("DD-MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("DD-MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 31 && duration <= 365) {
            // count month
            var tempMonth = [];
            var num = monthDuration;
            var tempNum = 1;
            tempMonth.push(moment(x));
            while (tempNum < num) {
              var tempDateMonth = moment(x).add(tempNum, "months");
              tempMonth.push(moment(tempDateMonth));
              tempNum++;
            }
            tempMonth.push(moment(y));

            for (let o = 0; o < tempMonth.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempMonth[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 365) {
            // count year
            var tempYear = [];
            var num = yearDuration;
            var tempNum = 1;

            var tempDateYear = moment(x).add(num, "years");

            tempYear.push(moment(x));
            while (tempNum <= num) {
              var tempDateYear = moment(x).add(tempNum, "years");
              tempYear.push(moment(tempDateYear));
              tempNum++;
            }
            for (let o = 0; o < tempYear.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempYear[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          }
        } else if (moment(this.date[1]).isBefore(moment(this.date[0]))) {
          this.betweenDate = "Date: " + this.date[1] + " - " + this.date[0];

          for (let j = 0; j < this.settlementList.length; j++) {
            temValue = moment(this.settlementList[j].created_at).format(
              "YYYY-MM-DD"
            );
            if (
              moment(temValue).isBetween(
                moment(this.date[1]),
                moment(this.date[0]),
                undefined,
                "[]"
              )
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }

          var x = new moment(this.date[1]);
          var y = new moment(this.date[0]);
          var duration = y.diff(x, "days");
          var monthDuration = y.diff(x, "months");
          var yearDuration = y.diff(x, "years");

          if (duration <= 31) {
            // count date
            var tempDay = [];
            var init = 1;
            var num = duration;
            tempDay.push(moment(this.date[0]));
            while (init <= num) {
              var tempDate = moment(this.date[0]).add(init, "days");
              tempDay.push(moment(tempDate));
              init++;
            }
            // assign data
            for (let o = 0; o < tempDay.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempDay[o]).format("DD-MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("DD-MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 31 && duration <= 365) {
            // count month
            var tempMonth = [];
            var num = monthDuration;
            var tempNum = 1;
            tempMonth.push(moment(x));
            while (tempNum < num) {
              var tempDateMonth = moment(x).add(tempNum, "months");
              tempMonth.push(moment(tempDateMonth));
              tempNum++;
            }
            tempMonth.push(moment(y));

            for (let o = 0; o < tempMonth.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempMonth[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 365) {
            // count year
            var tempYear = [];
            var num = yearDuration;
            var tempNum = 1;

            var tempDateYear = moment(x).add(num, "years");

            tempYear.push(moment(x));
            while (tempNum <= num) {
              var tempDateYear = moment(x).add(tempNum, "years");
              tempYear.push(moment(tempDateYear));
              tempNum++;
            }
            for (let o = 0; o < tempYear.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempYear[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          }
        } else if (moment(this.date[1]).isSame(moment(this.date[0]))) {
          this.betweenDate = "Date: " + this.date[0];
          var selectedDay = moment(this.date[0]).format("DD MMM");
          for (let j = 0; j < this.settlementList.length; j++) {
            if (
              moment(this.settlementList[j].created_at).format("YYYY-MM-DD") ==
              this.date[0]
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }
          // assign data
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = selectedDay;
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(this.date[0]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = selectedDay;
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else {
        if (this.date[0] != null) {
          this.betweenDate = "Date: " + this.date[0];
        } else {
          this.betweenDate = "Date: Today";
          for (let j = 0; j < this.settlementList.length; j++) {
            if (
              moment(this.settlementList[j].created_at).format("DD-MM-YYYY") ==
              moment().format("DD-MM-YYYY")
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }
        }
        var selectedDay = moment(this.date[0]).format("DD MMM");
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("YYYY-MM-DD") ==
            this.date[0]
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
        // assign data
        var obj = { x: "", y: "" };
        if (this.filterList.length == 0) {
          obj.x = selectedDay;
          obj.y = 0.0;
          this.series[0].data.push(obj);
        } else {
          var sales = 0.0;
          for (let l = 0; l < this.filterList.length; l++) {
            if (
              moment(this.date[0]).format("MM-YYYY") ===
              moment(this.filterList[l].created_at).format("MM-YYYY")
            ) {
              sales += parseFloat(this.filterList[l].total_sales);
            }
          }
          obj.x = selectedDay;
          obj.y = sales.toFixed(2).toString();
          this.series[0].data.push(obj);
        }
      }
      this.settlementListToPrint = [...this.filterList];

      // Sum of total_charge
      const totalChargeSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_charge) || 0,
        0
      );

      // Sum of total_bill
      const totalBillSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_bill) || 0,
        0
      );

      // Sum of total_tax
      const totalTaxSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_tax) || 0,
        0
      );

      // Sum of Gross sales
      const grossSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.gross_sales) || 0,
        0
      );

      // Sum of net sales
      const netSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.net_sales) || 0,
        0
      );

      // Sum of total_cancellation
      const totalCancellationSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_cancellation) || 0,
        0
      );

      // Sum of total_discount
      const totalDiscountSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_discount) || 0,
        0
      );

      // Sum of total_refund_amount
      const totalRefundAmountSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_refund_amount) || 0,
        0
      );

      // Sum of total_refund_bill
      const totalRefundBillSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseInt(entry.total_refund_bill) || 0,
        0
      );

      // Sum of total_sales
      const totalSalesSum = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.total_sales) || 0,
        0
      );
      // payment_method
      // Sum of total_sales
      const totalCashSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Cash_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalCardSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Card_sales) || 0,
        0
      );
      // Sum of total_sales
      const totalEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.ewallet_sales) || 0,
        0
      );
      // Sum of total_sales
      const totalCheckSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Check_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalOtherSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Other_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalTNGipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.tngipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBoostipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.boostipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalMaybankipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.maybankipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalUnionPayipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.unionpayipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabPayipay88Sales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.grabpayipay88_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalDuitnowEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.duitnowewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalTNGEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.tngewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBoostEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.boostewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabPayEwalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.grabpayewallet_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalFoodpandaSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Foodpanda_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalGrabFoodSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.GrabFood_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalWalletSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Wallets_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalAlipaySales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.Alipay_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalBaijiaSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.baijia_sales) || 0,
        0
      );

      // Sum of total_sales
      const totalShopeeFoodSales = this.settlementListToPrint.reduce(
        (sum, entry) => sum + parseFloat(entry.shopeefood_sales) || 0,
        0
      );

      // Clone the original data structure but empty other fields
      const summedData = {
        soft_delete: "",
        opened_at: "",
        updated_at: "",
        created_at: "",
        sync_status: 1,
        status: 0,
        settlement_by: "",
        settlement_by_user_id: "",
        total_charge: totalChargeSum.toFixed(2),
        total_tax: totalTaxSum.toFixed(2),
        total_cancellation: totalCancellationSum,
        total_discount: totalDiscountSum.toFixed(2),
        //payment
        Cash_sales: totalCashSales.toFixed(2),
        Card_sales: totalCardSales.toFixed(2),
        ewallet_sales: totalEwalletSales.toFixed(2),
        Check_sales: totalCheckSales.toFixed(2),
        Other_sales: totalOtherSales.toFixed(2),
        tngipay88_sales: totalTNGipay88Sales.toFixed(2),
        boostipay88_sales: totalBoostipay88Sales.toFixed(2),
        maybankipay88_sales: totalMaybankipay88Sales.toFixed(2),
        unionpayipay88_sales: totalUnionPayipay88Sales.toFixed(2),
        grabpayipay88_sales: totalGrabPayipay88Sales.toFixed(2),
        duitnowewallet_sales: totalDuitnowEwalletSales.toFixed(2),
        tngewallet_sales: totalTNGEwalletSales.toFixed(2),
        boostewallet_sales: totalBoostEwalletSales.toFixed(2),
        grabpayewallet_sales: totalGrabPayEwalletSales.toFixed(2),
        Foodpanda_sales: totalFoodpandaSales.toFixed(2),
        GrabFood_sales: totalGrabFoodSales.toFixed(2),
        Wallets_sales: totalWalletSales.toFixed(2),
        Alipay_sales: totalAlipaySales.toFixed(2),
        baijia_sales: totalBaijiaSales.toFixed(2),
        shopeefood_sales: totalShopeeFoodSales.toFixed(2),
        //end
        total_refund_amount: totalRefundAmountSum.toFixed(2),
        total_refund_bill: totalRefundBillSum,
        gross_sales: grossSalesSum.toFixed(2),
        net_sales: netSalesSum.toFixed(2),
        total_sales: totalSalesSum.toFixed(2),
        total_bill: totalBillSum,
        name: "",
        settlement_key: "",
        settlement_id: "Total",
      };

      this.settlementListToPrint.push(summedData);
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllSettlement();
    this.getAllPaymentType();
  },
  methods: {
    getAllPaymentType() {
      const params = new URLSearchParams();
      params.append("getAllPaymentType", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/payment/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.paymentList = response.data.payment;
          } else {
            console.log("no list get");
            this.paymentList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllSettlement() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            var daySales = 0.0;
            var weekSales = 0.0;
            var monthSales = 0.0;
            var grossSale = 0.0;
            var startWeek = moment().startOf("week");
            var endWeek = moment().endOf("week");
            var month = moment().format("MM");
            var day = moment().format("DD-MM-YYYY");
            this.settlementList = response.data.report;
            for (let j = 0; j < this.settlementList.length; j++) {
              if (
                moment(this.settlementList[j].created_at).format("MM") == month
              ) {
                monthSales += parseFloat(this.settlementList[j].total_sales);
              }
              if (
                moment(this.settlementList[j].created_at).format(
                  "DD-MM-YYYY"
                ) == day
              ) {
                this.filterList.push(this.settlementList[j]);

                daySales += parseFloat(this.settlementList[j].total_sales);
                grossSale +=
                  parseFloat(
                    this.settlementList[j].total_sales -
                      this.settlementList[j].total_tax
                  ) -
                  parseFloat(this.settlementList[j].total_charge) +
                  parseFloat(this.settlementList[j].total_discount);
              }
              if (
                moment(this.settlementList[j].created_at).isBetween(
                  moment(startWeek).add(1, "days"),
                  moment(endWeek).add(1, "days"),
                  undefined,
                  "[]"
                )
              ) {
                weekSales += parseFloat(this.settlementList[j].total_sales);
              }
            }

            this.dailySales = daySales.toFixed(2).toString();
            this.weeklySales = weekSales.toFixed(2).toString();
            this.monthlySales = monthSales.toFixed(2).toString();
            this.grossSales = grossSale.toFixed(2).toString();

            var obj = {
              x: moment().format("DD MMM"),
              y: this.dailySales,
            };
            this.series[0].data.push(obj);

            this.showChart = true;

            this.settlementListToPrint = [...this.filterList];

            // Sum of total_charge
            const totalChargeSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.total_charge) || 0,
              0
            );

            // Sum of total_bill
            const totalBillSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseInt(entry.total_bill) || 0,
              0
            );

            // Sum of total_tax
            const totalTaxSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.total_tax) || 0,
              0
            );

            // Sum of total_cancellation
            const totalCancellationSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseInt(entry.total_cancellation) || 0,
              0
            );

            // Sum of total_discount
            const totalDiscountSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.total_discount) || 0,
              0
            );
            // Sum of Gross sales
            const grossSalesSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.gross_sales) || 0,
              0
            );

            // Sum of net sales
            const netSalesSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.net_sales) || 0,
              0
            );

            // Sum of total_refund_amount
            const totalRefundAmountSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.total_refund_amount) || 0,
              0
            );

            // Sum of total_refund_bill
            const totalRefundBillSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseInt(entry.total_refund_bill) || 0,
              0
            );

            // Sum of total_sales
            const totalSalesSum = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.total_sales) || 0,
              0
            );

            // payment_method
            // Sum of total_sales
            const totalCashSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Cash_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalCardSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Card_sales) || 0,
              0
            );
            // Sum of total_sales
            const totalEwalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.ewallet_sales) || 0,
              0
            );
            // Sum of total_sales
            const totalCheckSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Check_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalOtherSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Other_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalTNGipay88Sales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.tngipay88_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalBoostipay88Sales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.boostipay88_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalMaybankipay88Sales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.maybankipay88_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalUnionPayipay88Sales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.unionpayipay88_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalGrabPayipay88Sales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.grabpayipay88_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalDuitnowEwalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.duitnowewallet_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalTNGEwalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.tngewallet_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalBoostEwalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.boostewallet_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalGrabPayEwalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.grabpayewallet_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalFoodpandaSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Foodpanda_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalGrabFoodSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.GrabFood_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalWalletSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Wallets_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalAlipaySales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.Alipay_sales) || 0,
              0
            );
            // Sum of total_sales
            const totalBaijiaSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.baijia_sales) || 0,
              0
            );

            // Sum of total_sales
            const totalShopeeFoodSales = this.settlementListToPrint.reduce(
              (sum, entry) => sum + parseFloat(entry.shopeefood_sales) || 0,
              0
            );

            // Clone the original data structure but empty other fields
            const summedData = {
              soft_delete: "",
              opened_at: "",
              updated_at: "",
              created_at: "",
              sync_status: 1,
              status: 0,
              settlement_by: "",
              settlement_by_user_id: "",
              total_charge: totalChargeSum.toFixed(2),
              total_tax: totalTaxSum.toFixed(2),
              //payment
              Cash_sales: totalCashSales.toFixed(2),
              Card_sales: totalCardSales.toFixed(2),
              ewallet_sales: totalEwalletSales.toFixed(2),
              Check_sales: totalCheckSales.toFixed(2),
              Other_sales: totalOtherSales.toFixed(2),
              tngipay88_sales: totalTNGipay88Sales.toFixed(2),
              boostipay88_sales: totalBoostipay88Sales.toFixed(2),
              maybankipay88_sales: totalMaybankipay88Sales.toFixed(2),
              unionpayipay88_sales: totalUnionPayipay88Sales.toFixed(2),
              grabpayipay88_sales: totalGrabPayipay88Sales.toFixed(2),
              duitnowewallet_sales: totalDuitnowEwalletSales.toFixed(2),
              tngewallet_sales: totalTNGEwalletSales.toFixed(2),
              boostewallet_sales: totalBoostEwalletSales.toFixed(2),
              grabpayewallet_sales: totalGrabPayEwalletSales.toFixed(2),
              Foodpanda_sales: totalFoodpandaSales.toFixed(2),
              GrabFood_sales: totalGrabFoodSales.toFixed(2),
              Wallets_sales: totalWalletSales.toFixed(2),
              Alipay_sales: totalAlipaySales.toFixed(2),
              baijia_sales: totalBaijiaSales.toFixed(2),
              shopeefood_sales: totalShopeeFoodSales.toFixed(2),
              //end
              total_cancellation: totalCancellationSum,
              total_discount: totalDiscountSum.toFixed(2),
              total_refund_amount: totalRefundAmountSum.toFixed(2),
              gross_sales: grossSalesSum.toFixed(2),
              net_sales: netSalesSum.toFixed(2),
              total_refund_bill: totalRefundBillSum,
              total_sales: totalSalesSum.toFixed(2),
              total_bill: totalBillSum,
              name: "",
              settlement_key: "",
              settlement_id: "Total",
            };

            this.settlementListToPrint.push(summedData);
          } else {
            var moment = require("moment");
            this.dailySales = "0.00";
            this.weeklySales = "0.00";
            this.monthlySales = "0.00";
            this.grossSales = "0.00";
            var obj = {
              x: moment().format("DD MMM"),
              y: this.dailySales,
            };
            this.series[0].data.push(obj);

            this.showChart = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleClick(value) {
      this.billList = [];
      this.billListLoading = true;
      const params = new URLSearchParams();
      params.append("getBillDetailTest", "done");
      params.append("settlement_key", value.item.settlement_key);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.billList = response.data.report;
            this.billListLoading = false;
          } else {
            this.billList = [];
            this.billListLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadAndClose() {
      this.dialogViewIndivisualBill = false;
      this.generatePDF();
    },

    updateIsMobile() {
      this.isMobile = window.innerWidth <= 600;
    },

    rowClick(value) {
      this.dialogLoading = true;
      this.orderNo = value.order_number;
      this.diningMethod = value.dining_name;
      this.closeBy = value.close_by;
      this.orderAt = value.created_at;
      this.subtotal = value.subtotal;
      this.rounding = value.rounding;
      this.total = value.final_amount;
      this.discountAmount = value.discount_amount;
      this.taxAmount = value.tax_amount;
      this.chargeAmount = value.charge_amount;

      let logPaymentMethodText = "";

      if (value.Cash_sales != 0) {
        logPaymentMethodText = "Paid By Cash: " + value.Cash_sales + "\n";
      }
      if (value.Card_sales != 0) {
        logPaymentMethodText += "Paid By Card: " + value.Card_sales + "\n";
      }
      if (value.ewallet_sales != 0) {
        logPaymentMethodText +=
          "Paid By E-wallet: " + value.ewallet_sales + "\n";
      }
      if (value.Check_sales != 0) {
        logPaymentMethodText += "Paid By Check: " + value.Check_sales + "\n";
      }
      if (value.Other_sales != 0) {
        logPaymentMethodText += "Paid By Other: " + value.Other_sales + "\n";
      }
      if (value.tngipay88_sales != 0) {
        logPaymentMethodText +=
          "Paid By TouchNGo eWallet Scan (ipay88): " +
          value.tngipay88_sales +
          "\n";
      }
      if (value.boostipay88_sales != 0) {
        logPaymentMethodText +=
          "Paid By Boost Wallet Scan (ipay88): " +
          value.boostipay88_sales +
          "\n";
      }
      if (value.maybankipay88_sales != 0) {
        logPaymentMethodText +=
          "Paid By MaybankQR Scan (ipay88): " +
          value.maybankipay88_sales +
          "\n";
      }
      if (value.unionpayipay88_sales != 0) {
        logPaymentMethodText +=
          "Paid By Unionpay Scan (ipay88): " +
          value.unionpayipay88_sales +
          "\n";
      }
      if (value.grabpayipay88_sales != 0) {
        logPaymentMethodText +=
          "Paid By GrabPay Scan (ipay88): " + value.grabpayipay88_sales + "\n";
      }
      if (value.duitnowewallet_sales != 0) {
        logPaymentMethodText +=
          "Paid By Duitnow eWallet: " + value.duitnowewallet_sales + "\n";
      }
      if (value.tngewallet_sales != 0) {
        logPaymentMethodText +=
          "Paid By TouchNGo eWallet: " + value.tngewallet_sales + "\n";
      }
      if (value.boostewallet_sales != 0) {
        logPaymentMethodText +=
          "Paid By Boost eWallet: " + value.boostewallet_sales + "\n";
      }
      if (value.grabpayewallet_sales != 0) {
        logPaymentMethodText +=
          "Paid By GrabPay eWallet: " + value.grabpayewallet_sales + "\n";
      }
      if (value.Foodpanda_sales != 0) {
        logPaymentMethodText +=
          "Paid By Foodpanda: " + value.Foodpanda_sales + "\n";
      }
      if (value.GrabFood_sales != 0) {
        logPaymentMethodText +=
          "Paid By GrabFood: " + value.GrabFood_sales + "\n";
      }
      if (value.Wallets_sales != 0) {
        logPaymentMethodText +=
          "Paid By Wallets: " + value.Wallets_sales + "\n";
      }
      if (value.Alipay_sales != 0) {
        logPaymentMethodText += "Paid By Alipay: " + value.Alipay_sales + "\n";
      }
      if (value.baijia_sales != 0) {
        logPaymentMethodText +=
          "Paid By Bai Jia 百家: " + value.baijia_sales + "\n";
      }
      if (value.shopeefood_sales != 0) {
        logPaymentMethodText +=
          "Paid By Shopee Food: " + value.shopeefood_sales + "\n";
      }

      logPaymentMethodText = logPaymentMethodText.replace(/\n/g, "<br>");

      this.paymentMethodText = logPaymentMethodText;
      // Set it to innerHTML to display line breaks
      document.getElementById("output").innerHTML = this.paymentMethodText;

      const params = new URLSearchParams();
      params.append("getIndivisualBillDetail", "done");
      params.append("order_key", value.order_key);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialogLoading = false;
            this.individualBill = response.data.report;
            this.dialogViewIndivisualBill = true;
          } else {
            this.dialogLoading = false;
            this.individualBill = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generatePDF(){
      this.$refs.html2Pdf.generatePdf();
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: large;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>

<style scoped>
.user-name {
  font-size: 1.5em;
}
.order-info {
  font-size: 1.3em;
  margin-bottom: 30px;
}
#customers {
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
}
#customers th, #customers td {
  padding: 8px;
  text-align: left;
}
.align-right {
  text-align: right;
}
.total-label {
  font-weight: bold;
}
.total-value {
  font-weight: bold;
}
.payment-method {
  font-size: 1em;
  margin-top: 10px;
  text-align: right;
}
@media (max-width: 600px) {
  .user-name {
    font-size: 1.2em;
  }
  .order-info {
    font-size: 0.9em;
    margin-bottom: 15px;
  }
  #customers th, #customers td {
    font-size: 0.8em;
    padding: 4px;
  }
  .total-label, .total-value {
    font-size: 1em;
  }
  .payment-method {
    font-size: 0.9em;
    text-align: left;
  }
}
</style>
